@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* font-family: 'Poppins'; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.main
{
    box-shadow: 0 10px 60px 18px rgba(0,0,0,0.05);
}
/* .react-calendar
{ 
  width: auto!important;
  border-style: none!important;

}


 .react-calendar__viewContainer
 {
   background-color: white
 }
 .react-calendar__month-view__weekdays__weekday
 {
  color: #8eb203;
 }
 
 .react-calendar__navigation__next2-button,
 .react-calendar__navigation__prev2-button
 {
  display: none;
 }

.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button
{
  font-size: 20px;
}


 /* .react-calendar__navigation {
   

  .react-calendar__navigation__label {
      font-size: 32px;
  } 
} 
 
 .react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #ae0e0e;
}

.react-calendar__tile {
   height: 70px; 
}

.react-calendar__month-view__weekdays__weekday {
 }

.react-calendar__tile--active {
  background: #e70220; 
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #e70220;
}

.react-calendar__tile,
.react-calendar__month-view__days__day {
  font-size: 14px;
}
 
.react-calendar__month-view__days__day--neighboringMonth
{
  color: grey!important;
}
.react-calendar__month-view__days__day--weekend
{
  color: #8eb203!important;
}
.react-calendar__month-view__days__day
{
  color: #8eb203;
}  */


.rmdp-calendar {
  width: 100%; 
 

}
.rmdp-day-picker {
 width: 100%;
 display: inline-grid!important;

}
.rmdp-range.start:not(.force)  {
   border-radius: 50%;
}
.rmdp-range:not(.force):not(:first-child):not(:last-child) {
  border-radius: 50%;
}

.rmdp-range.end:not(.force):last-child {
  border-radius: 50%;
}
.rmdp-range:has(.available) {
  width:100%;
  background-color:#8eb203; 
  color: #fff!important;
}
.rmdp-range:has(.booked) {
  background-color: #c3b9c0 ;
}
.rmdp-range:has(.unavailable) {
  background-color: #0075FF;
  
}
  
/* div.available {
  background-color: #8eb203;
  width: 15px;
  height: 15px;
  border-radius: 50%;
} */

/* div.booked {
  background-color: #0075FF;
  width: 15px;
  height: 15px;
  border-radius: 50%;
} */
/* div.unavailable {
  background-color: #b9bdc3;
  width: 15px;
  height: 15px;
  border-radius: 50%;
} */




.un-availble p {
  margin: 0;
}


/* CalendarComponent.css */
/* .small-screen {
  position: relative;
}

.small-screen .react-multi-date-picker-date-panel {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #c2c8d2;
  margin-top: 10px;  
  z-index: 1;  
} */
.rmdp-wrapper .rmdp-shadow{
  position: relative;
  display: grid;
  grid-column: 1;
} 
.rmdp-panel .right
{
position: absolute;
 }


 .before
 {
color: #7e9d02;
 }
 .after
 {
color: white
 }
 .selected
 {
color: white
 }
 .not-selected
 {
color: #7e9d02;
 }

 @keyframes gridColumnAnimation {
  from {
    grid-column: 2;
  }
}

  @media screen and (max-width: 600px) {
    body {
      grid-column: 1;
    }
  }

.rmdp-wrapper .rmdp-shadow {
  position: relative;
  display: grid;
  animation: gridColumnAnimation 1s forwards;
}

.rmdp-panel .right {
  position: absolute;
}

.heading {
  display: flex;
  justify-content: center;
  font-size: 36px;
  font-weight: 600;
  color: #0075FF;
}

.button {
  cursor: pointer;
  background-color: #0075FF; 
  border-radius: 10px; 
  text-align: center;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px rgb(255, 255, 255) inset !important;
}
.modal {
  z-index: 50; 
  position: relative; 
}

.gm-style-cc {
  display: none;
}

/* .spinner{
  position: absolute;
  height: 100px;
  width: 100px;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  background-size: 100%;
} */




.custom-scrollbar {
  overflow-y: scroll; /* Use 'scroll' instead of 'hidden' */
  scrollbar-width: thin; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer/Edge */

}

/*  


.custom-scrollbar {
  overflow: scroll;
  position: relative;
} */

/* .custom-scrollbar::-webkit-scrollbar {
  width: 0px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 6px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #fff;
} 
.scroll-container {
  overflow: hidden;
}

.scroll-text {
  height: 100%;
  text-align: center;
  transform: translateX(-100%);
  animation: my-animation 5s linear infinite;
} */

.custom-scrollbar {
  overflow-y: scroll;
  scrollbar-width: thin; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer/Edge */
  width: 100%; /* Ensure the container takes up the full width */
 }

.custom-scrollbar::-webkit-scrollbar {
  /* width: 0px; */
  -webkit-appearance: none;
  width: 0.2px;
  height: 3px;
 
}
 

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;

}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 6px;
 }

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: transparent;

}

 
  @media (min-width: 1024px) {
    .custom-scrollbar {
      scrollbar-width: thin; /* For Firefox */
      scrollbar-color: transparent transparent; /* For Firefox */
  
      /* For WebKit browsers (Safari, Chrome) */
      &::-webkit-scrollbar {
        width: 1px; /* Adjust the width as needed */
      }
      &::-webkit-scrollbar-thumb {
        background-color: transparent; /* Adjust the color as needed */
      }
    }
  

  .custom-scrollbar::-webkit-scrollbar {
    width: 0; /* Set the width to 0 for WebKit browsers */
  }
}


  


 




/* Add this to your CSS file */
@media screen and (max-width: 600px) {
  .country-name {
    font-size: 18px; /* Adjust the font size for smaller screens */
  }
}





.scrollable-content {
  overflow-y: auto;
  height: calc(100vh);
}








 

/* .custom-scrollbar {
  overflow-y: scroll;
  position: relative;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px; 
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #a0aec0;  
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #718096;  
}

.scroll-container {
  overflow: hidden;
  height: 200px;  
}

.scroll-text {
  height: 100%;
  text-align: center;
  transform: translateX(-100%);
  animation: my-animation 5s linear infinite;
}

@keyframes my-animation {
  to {
    transform: translateX(0%);
  }
}

@media screen and (max-width: 767px) {
  .custom-scrollbar {
    overflow-y: hidden;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  .custom-scrollbar:hover {
    overflow-y: scroll;
  }
} */











.selected {
  background-color: rgb(29 78 216);
  color: #fff;  
} 
 
.search-container {
  position: relative;
}

.suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.suggestions li {
  padding: 8px;
  cursor: pointer;
}

.suggestions li:hover {
  background-color: #f2f2f2;
}



 

@keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}


.fixed-hamburger {
  position: fixed;
  left: 30px; /* Adjust the left position as needed */
  top: 0;
  z-index: 10;
  transition: left 0.3s ease; /* Add a smooth transition effect if needed */
}
 